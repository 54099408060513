import './Footer.scss';

const Footer = () => {

    return (
        <div className="footer-cont">
            <div className="footer">

            </div>
        </div>
    )
}

export default Footer;